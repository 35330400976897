
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button";

class AdminEventConfirmCloseEvent extends React.Component {
	state = {
		isConfirming: false
	}

	render() {
		const {winningBallotsCount, handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				{winningBallotsCount > 0
					? <>
						<h2>Mark Event As 'Tickets Assigned'?</h2>
						<p>By clicking 'Yes' you confirm that all winners have been finalised. We will send unsuccessful
							emails
							to those who did not win and close this event.
							<br /><br /><b>Attention: This locks in the winners, you won’t be able reverse this
								status</b></p>
					</>
					: <>
						<h2>Close Event Without Assigning Winners?</h2>
						<p>By clicking 'Yes' you confirm that the event will be "silently" closed with no winners assigned
							<br /><br /><b>Attention: You won’t be able reverse this
								status</b></p>
					</>}
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Cancel</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorEndeavour>Yes</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = () => {
		const {handleConfirm} = this.props
		this.setState({
			isConfirming: true
		})
		handleConfirm()
	}
}

export default AdminEventConfirmCloseEvent
