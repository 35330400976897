
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTicketAlt} from '@fortawesome/pro-solid-svg-icons'
import {faMinus, faPlus} from "@fortawesome/pro-regular-svg-icons"
import moment from "moment";

// Context
import NotificationContext from '../../../contexts/notification'

class AdminEventBallotsTableRow extends React.Component {
	static contextType = NotificationContext

	render() {
		const {ballot, isSelected, isWinner} = this.props

		let classNames = Classnames([
			'admin-table__row',
			{
				'admin-table__row--pending': ballot.status_id === 1,
			},
			{
				'admin-table__row--success': [2, 7, 8].includes(ballot.status_id),
			},
			{
				'admin-table__row--winner-accepted': ballot.status_id === 2,
			},
			{
				'admin-table__row--winner-rejected': ballot.status_id === 8,
			},
			{
				'admin-table__row--removed': ballot.status_id === 3,
			},
			{
				'admin-table__row--checked': isSelected,
			},
			{
				'admin-table__row--winner': isWinner,
			},
		])

		let ticketsDecreaseClasses = Classnames([
			'action',
			'action--decrease',
			{
				'action--disabled': ballot.tickets_requested === 1,
			},
		])

		let ticketsIncreaseClasses = Classnames([
			'action',
			'action--increase',
			{
				'action--disabled': ballot.tickets_requested >= ballot.event.max_tickets_per_transaction,
			},
		])

		return <tr className={classNames}>
			<td className="admin-table__cell admin-table__cell--checkbox">
				<input id={`field-assign-${ballot.id}`} type="checkbox" onClick={(clickEvent) => this.handleInputToggle(clickEvent)} checked={isSelected} />
				<label htmlFor={`field-assign-${ballot.id}`}><span className="sr-only">Assign</span></label>
			</td>
			<td className="admin-table__cell admin-table__cell--id">{ballot.id}</td>
			<td className="admin-table__cell admin-table__cell--name">{`${ballot.user?.first_name} ${ballot.user?.last_name}`}</td>
			<td className="admin-table__cell admin-table__cell--email">{ballot.user?.email}</td>
			<td className="admin-table__cell admin-table__cell--organisation">{ballot.user?.organisation?.name}</td>
			<td className="admin-table__cell admin-table__cell--created">{moment(ballot.created_at).format('DD MMM YYYY')}</td>
			<td className="admin-table__cell admin-table__cell--tickets">
				<div className="actions-wrap">
					<button onClick={(clickEvent) => this.handleTicketQtyUpdate(clickEvent, 'decrease')} className={ticketsDecreaseClasses}>
						<FontAwesomeIcon icon={faMinus} />
					</button>
					<span className="qty">{ballot.tickets_requested}</span>
					<button onClick={(clickEvent) => this.handleTicketQtyUpdate(clickEvent, 'increase')} className={ticketsIncreaseClasses}>
						<FontAwesomeIcon icon={faPlus} />
					</button>
				</div>
			</td>
			<td className="admin-table__cell admin-table__cell--selected">
				{ballot.status_id === 7 && <span className="label label--winner label--winner-pending"><FontAwesomeIcon icon={faTicketAlt} /> Pending</span>}
				{ballot.status_id === 2 && <span className="label label--winner label--winner-accepted"><FontAwesomeIcon icon={faTicketAlt} /> Accepted</span>}
				{ballot.status_id === 8 && <span className="label label--winner label--winner-rejected"><FontAwesomeIcon icon={faTicketAlt} /> Rejected</span>}
			</td>
		</tr>
	}

	handleInputToggle = async (clickEvent) => {
		const {ballot, handleBallotToggle} = this.props

		handleBallotToggle(clickEvent.target.checked, ballot)
	}

	handleTicketQtyUpdate = async (clickEvent, action = 'decrease') => {
		if ( clickEvent.target.classList.contains('action--disabled') ||
			clickEvent.target.closest('.action').classList.contains('action--disabled') ) {
			// clicked a disabled action
			return false
		}

		const {ballot, handleTicketQtyUpdate} = this.props
		let tickets_requested = ballot.tickets_requested

		if (action === 'decrease' && ballot.tickets_requested > 0) {
			tickets_requested = ballot.tickets_requested - 1;
		}
		else if (action === 'increase') {
			tickets_requested = ballot.tickets_requested + 1;
		}

		handleTicketQtyUpdate(ballot.id, tickets_requested)
	}
}

export default AdminEventBallotsTableRow
