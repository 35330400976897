
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class BallotService {
	async get(id) {
		return await apiService.get(`ballots/${id}`)
	}

	async payGet(token, id) {
		return await apiService.get(`ballots/pay/${token}/${id}`)
	}

	async update(id, data) {
		const requestOptions = {
			body: JSON.stringify(data),
		}
		return await apiService.put(`ballots/${id}/update`, requestOptions)
	}

	async remove(id) {
		return await apiService.put(`ballots/${id}/remove`)
	}

	async reenter(id) {
		return await apiService.put(`ballots/${id}/re-enter`)
	}

	async winnerDecision(data) {
		const requestOptions = {
			body: JSON.stringify(data),
		}
		return await apiService.post(`ballots/winner-decision`, requestOptions)
	}
}
