
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import AdminEventBallotsTableRow from './adminEventBallotsTableRow'

class AdminEventBallotsTable extends React.Component {
	render() {
		const {ballots, winningBallots, winningBallotsCount, selectedBallots, emptyMessage,
			handleBallotToggle, handleTicketQtyUpdate} = this.props

		const classNames = Classnames([
			'admin-table admin-table--events admin-table--events-assign-tickets wrap--scrollx',
		])

		const winningBallotIds = winningBallots.map((winningBallot) => winningBallot.id)
		const selectedBallotIds = selectedBallots.map((selectedBallot) => selectedBallot.id)

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th><span className="sr-only">Assign</span></th>
							<th>ID</th>
							<th>User</th>
							<th>Email</th>
							<th>Employer</th>
							<th>Applied</th>
							<th>Tickets</th>
							<th>Winners ({winningBallotsCount})</th>
						</tr>
					</thead>
					<tbody>
					{
						winningBallots.map((ballot) => !selectedBallotIds.includes(ballot.id) && <AdminEventBallotsTableRow
							key={ballot.id}
							ballot={ballot}
							handleBallotToggle={handleBallotToggle}
							handleTicketQtyUpdate={handleTicketQtyUpdate}
							isWinner />)
					}
					{
						selectedBallots.map((ballot) => <AdminEventBallotsTableRow
							key={ballot.id}
							ballot={ballot}
							handleBallotToggle={handleBallotToggle}
							handleTicketQtyUpdate={handleTicketQtyUpdate}
							isSelected />)
					}
					{
						ballots?.length
							? ballots.map((ballot) => !selectedBallotIds.includes(ballot.id) && !winningBallotIds.includes(ballot.id) && <AdminEventBallotsTableRow
								key={ballot.id}
								ballot={ballot}
								handleBallotToggle={handleBallotToggle}
								handleTicketQtyUpdate={handleTicketQtyUpdate} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminEventBallotsTable
