
// Libraries
import * as React from 'react'
import _ from 'lodash'
import moment from 'moment'

// Services
import ApiService from '../../../services/api'
import BallotService from '../../../services/ballot'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminEventsTableHeader from '../../../components/admin/events/adminEventsTableHeader'
import Pagination from '../../../components/pagination'
import AdminEventBallotsTable from '../../../components/admin/events/adminEventBallotsTable'
import AdminEventConfirmAssignWinners from '../../../components/admin/events/adminEventConfirmAssignWinners'
import AdminEventConfirmCloseEvent from '../../../components/admin/events/adminEventConfirmCloseEvent'
import AdminBallotAssignWinnersForm from '../../../components/admin/events/adminBallotAssignWinnersForm'
import AdminBallotConfirmDelete from '../../../components/admin/events/adminBallotConfirmDelete'
import Button from '../../../components/button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft, faTicketAlt, faTrashAlt} from '@fortawesome/pro-solid-svg-icons'
import {Link} from 'gatsby'
import NumberFormat from "react-number-format"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminEventsBallotsPage extends React.Component {
	state = {
		activeFilters: {
			filter: {}
		},
		event: {},
		ballots: [],
		pagination: [],
		emptyMessage: 'Retrieving ballot data ...',
		selectedBallots: [],
		selectedBallotsCount: 0,
		selectedBallotsTicketCount: 0,
		winningBallots: [],
		winningBallotsCount: 0,
		winningTicketsCount: 0,
		modalConfirmDeleteShow: false,
		modalAssignWinnersFormShow: false,
		modalConfirmAssignWinnersShow: false,
		modalConfirmCloseEventShow: false,
		assignWinnersInputValues: {
			tickets_available: 0
		},
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, event, ballots, pagination, emptyMessage, selectedBallots, selectedBallotsCount,
			selectedBallotsTicketCount, winningBallots, winningBallotsCount, winningTicketsCount,
			modalConfirmDeleteShow, modalAssignWinnersFormShow, modalConfirmAssignWinnersShow,
			modalConfirmCloseEventShow, assignWinnersInputValues} = this.state

		const ticketsAvailable = event.tickets_available + parseInt(assignWinnersInputValues.tickets_available)
		const assignedTickets = selectedBallotsTicketCount + winningTicketsCount

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table nav-blue-half" hideSideNav>
				<Seo title="Events Management" />
				<div className="admin-table__wrap admin-table__wrap--events-ballots admin-table__wrap--buttons-bottom">
					<Link to="/admin/events" className="back"><FontAwesomeIcon icon={faArrowLeft} title="Close icon" /> Back to events</Link>
					<AdminEventsTableHeader
						event={event}
						subTitleText="Ballots for"
						titleText={event.name}
						titleIcon={faTicketAlt}
						searchValue={activeFilters.search}
						searchHandler={_.debounce(this.handleSearchChange, 500)}
						csvButton />

					<div className="event-info">
						<div className="column">
							<p>{event.artist?.name} - {event.venue?.addresses[0].city} - {event.venue?.name}</p>
						</div>
						<div className="column">
							<p>{moment(event.starts).format('DD MMM YYYY')}</p>
						</div>
						<div className="column">
							<p>STATUS: {event.status?.name}</p>
						</div>
						<div className="flex-break">&nbsp;</div>
						{ assignedTickets <= ticketsAvailable
							? <>
								<div className="column">
									<p><span className="pill"><b>{assignedTickets}</b></span> Assigned tickets out of <b>{ticketsAvailable}</b></p>
								</div>
								<div className="column">
									<p><span className="pill pill--winner-pending"><b>{event.winner_pending_tickets_count}</b></span> Pending</p>
								</div>
								<div className="column">
									<p><span className="pill pill--winner-accepted"><b>{event.winner_accepted_tickets_count}</b></span> Accepted</p>
								</div>
								<div className="column">
									<p><span className="pill pill--winner-rejected"><b>{event.winner_rejected_tickets_count}</b></span> Rejected</p>
								</div>
								<div className="flex-break">&nbsp;</div>
								<div className="column">
									<p><span className="pill"><b>{event.winner_requested_tickets_sum}</b></span> Requested tickets</p>
								</div>
								<div className="column">
									<p><span className="pill"><b><NumberFormat value={event.winner_fees_sum} displayType={"text"} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'£'}/></b></span> Fees</p>
								</div>
							</>
							: <>
								<div className="column column--error">
									<p><span className="pill"><b>{assignedTickets}</b></span> Assigned tickets out of <b>{ticketsAvailable}</b></p>
								</div>
								<div className="column column--error">
									<p>Too many tickets selected, please uncheck some of the ballots</p>
								</div>
							</>
						}
					</div>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminEventBallotsTable
						winningBallots={winningBallots}
						winningBallotsCount={winningBallotsCount}
						selectedBallots={selectedBallots}
						ballots={ballots}
						emptyMessage={emptyMessage}
						handleBallotToggle={this.handleBallotToggle}
						handleTicketQtyUpdate={this.handleTicketQtyUpdate} />

					<div className="buttons-bottom">
						<div className="col-left">
							{event.status_id !== 4 && <>
								<p className="select-count">{selectedBallotsCount} ballots selected</p>
								<Button onClick={() => this.handleConfirmDeleteShow()} iconOnly danger>
									<FontAwesomeIcon icon={faTrashAlt} title="Remove icon" />
									<span className="sr-only">Remove Ballots</span>
								</Button>
							</>}
						</div>
						<div className="col-right">
							{[1, 3].includes(event.status_id) && <Button
								colorEndeavour
								onClick={(clickEvent) => this.handleAssignWinnersFormShow(clickEvent)}>
								Assign Winners
							</Button>}
							{(selectedBallots.length > 0 && assignedTickets <= ticketsAvailable) && <Button
								colorEndeavour
								onClick={(clickEvent) => this.handleConfirmAssignWinnersShow(clickEvent)}>
								Confirm Winners
							</Button>}
							{![4, 5].includes(event.status_id) && <Button
								colorEndeavour
								onClick={(clickEvent) => this.handleConfirmCloseEventShow(clickEvent)}>
								Close Event
							</Button>}
						</div>
					</div>

					{modalConfirmDeleteShow && <AdminBallotConfirmDelete
						selectedBallotsCount={selectedBallotsCount}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={this.handleConfirmDelete} />}

					{modalAssignWinnersFormShow && <AdminBallotAssignWinnersForm
						event={event}
						handleClose={() => this.setState({modalAssignWinnersFormShow: false})}
						handleConfirm={this.handleConfirmReviewWinners} />}

					{modalConfirmAssignWinnersShow && <AdminEventConfirmAssignWinners
						event={event}
						handleClose={() => this.setState({modalConfirmAssignWinnersShow: false})}
						handleConfirm={this.handleConfirmAssignWinners} />}

					{modalConfirmCloseEventShow && <AdminEventConfirmCloseEvent
						event={event}
						handleClose={() => this.setState({modalConfirmCloseEventShow: false})}
						handleConfirm={this.handleConfirmCloseEvent}
						winningBallotsCount={winningBallotsCount} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const {eventId} = this.props
		this.getEvent(eventId)
		this.getWinningBallots(eventId)

		Promise.all([
			this.getBallots(eventId, activeFilters)
				.catch(() => addNotification('There was an error fetching the ballots.', 'error'))
		]).then(() => {
			this.setState({
				emptyMessage: "No pending or waiting ballots found, try adjusting your filters"
			})
		})
	}

	getEvent = (eventId) => {
		const apiService = new ApiService()
		return apiService.get(`events/${eventId}`)
			.then( (response) => {
				this.setState({event: response.data})
			}).catch(err => console.error(err))
	}

	getBallots = (eventId, queryParams) => {
		const apiService = new ApiService()
		queryParams['filter']['event_id'] = eventId
		queryParams['filter']['pending_or_waiting'] = 1
		return apiService.get('ballots', {}, queryParams)
			.then( (response) => {
				this.setState({
					ballots: response.data.data,
					pagination: response.data,
					activeFilters: queryParams
				})
			}).catch(err => console.error(err))
	}

	getWinningBallots = (eventId) => {
		const apiService = new ApiService()
		const queryParams = {
			nopagination: 1,
			filter: {
				event_id: eventId,
				winnerAll: 1
			}
		}
		return apiService.get('ballots', {}, queryParams)
			.then( (response) => {
				let eventMaxTicketsPerTransaction = 0
				if ( response.data.length ) {
					eventMaxTicketsPerTransaction = response.data[0].event.max_tickets_per_transaction
				}

				this.setState({
					winningBallots: response.data,
					winningBallotsCount: response.data.length,
					winningTicketsCount: response.data.length * eventMaxTicketsPerTransaction
				})
			}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {event, activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getBallots(event.id, activeFilters)
				.catch(() => addNotification('There was an error filtering the ballots', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {event, activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getBallots(event.id, activeFilters)
				.catch(() => addNotification('There was an error filtering the ballots.', 'error'))
		})
	}

	handleBallotToggle = (isChecked, ballot) => {
		const {selectedBallots} = this.state

		if (isChecked) {
			// add to selectedBallots
			selectedBallots.push(ballot)
		}
		else {
			// remove from selectedBallots
			selectedBallots.forEach((selectedBallot, key) => {
				if (selectedBallot.id === ballot.id) {
					selectedBallots.splice(key, 1)
				}
			})
		}

		let selectedBallotTicketCount = 0
		selectedBallots.forEach((selectedBallot) => {
			if (selectedBallot.status_id !== 2) {
				selectedBallotTicketCount = selectedBallotTicketCount + selectedBallot.event.max_tickets_per_transaction
			}
		})

		this.setState({
			selectedBallots: selectedBallots,
			selectedBallotsCount: selectedBallots.length,
			selectedBallotsTicketCount: selectedBallotTicketCount
		})
	}

	handleTicketQtyUpdate = async (ballotId, qty) => {
		const {addNotification} = this.context

		const ballotService = new BallotService()
		const ballotResponse = await ballotService.update(ballotId, {tickets_requested: qty})
		if (ballotResponse.success) {
			this.updateStateBallots(ballotResponse.data)
		}
		else {
			addNotification('Unable to update tickets', 'error')
		}
	}

	handleConfirmDeleteShow = () => {
		const {selectedBallotsCount} = this.state
		const {addNotification} = this.context

		if (selectedBallotsCount > 0) {
			this.setState({
				modalConfirmDeleteShow: true
			})
		}
		else {
			addNotification('No ballots selected', 'error')
		}
	}

	handleConfirmDelete = async () => {
		const {event, winningBallots, selectedBallots, selectedBallotsCount} = this.state
		const {addNotification} = this.context

		if (selectedBallotsCount > 0) {
			// prepare data
			const ballotData = JSON.stringify({
				event_id: event.id,
				ballots: selectedBallots.map((selectedBallot) => selectedBallot.id.toString()),
			})

			// submit data
			const apiService = new ApiService()
			await apiService.put('ballots/remove', {body: ballotData})
				.then(async (ballotResponse) => {
					if (ballotResponse.success) {
						// remove ballots from view
						ballotResponse.data.ballots.forEach((removedBallot) => {
							this.updateStateBallots(removedBallot, true)

							winningBallots.forEach((winningBallot, key) => {
								if (winningBallot.id === removedBallot.id) {
									winningBallots.splice(key, 1)
								}
							})
						})

						let winningTicketsCount = 0
						winningBallots.forEach((winningBallot) => {
							winningTicketsCount = winningTicketsCount + winningBallot.tickets_requested
						})

						// hide modal & reset selected ballots
						this.setState({
							modalConfirmDeleteShow: false,
							winningBallots: winningBallots,
							winningBallotsCount: winningBallots.length,
							winningTicketsCount: winningTicketsCount,
							selectedBallots: [],
							selectedBallotsCount: 0,
							selectedBallotsTicketCount: 0
						})

						// success notification
						addNotification('Ballots removed', 'success')
					}
					else {
						addNotification('Unable to remove ballots', 'error')
					}
				})
				.catch(err => console.error(err))
		}
		else {
			addNotification('No ballots selected', 'error')
		}
	}

	handleAssignWinnersFormShow = (clickEvent) => {
		let open = true;
		if ( clickEvent.target.classList.contains('disabled') ||
			clickEvent.target.closest('.button').classList.contains('disabled') ) {
			open = false;
		}

		this.setState({
			modalAssignWinnersFormShow: open
		})
	}

	handleConfirmReviewWinners = async (ballots, inputValues) => {
		const {event} = this.state
		const selectedBallots = []

		ballots.forEach((ballot) => {
			selectedBallots.push(ballot)
		})

		this.setState({
			modalAssignWinnersFormShow: false,
			selectedBallots: selectedBallots,
			selectedBallotsCount: selectedBallots.length,
			selectedBallotsTicketCount: selectedBallots.length * event.max_tickets_per_transaction,
			assignWinnersInputValues: inputValues
		})
	}

	handleConfirmAssignWinnersShow = (clickEvent) => {
		let open = true;
		if ( clickEvent.target.classList.contains('disabled') ||
			clickEvent.target.closest('.button').classList.contains('disabled') ) {
			open = false;
		}

		this.setState({
			modalConfirmAssignWinnersShow: open
		})
	}

	handleConfirmAssignWinners = async () => {
		const {event, selectedBallots, selectedBallotsTicketCount, winningTicketsCount, assignWinnersInputValues} = this.state
		const {addNotification} = this.context

		const ticketsAvailable = event.tickets_available + parseInt(assignWinnersInputValues.tickets_available)
		if ((selectedBallotsTicketCount + winningTicketsCount) <= ticketsAvailable) {
			// prepare data
			const postData = JSON.stringify({
				event_id: event.id,
				ballots: selectedBallots.map((selectedBallot) => selectedBallot.id.toString()),
				tickets_available: assignWinnersInputValues.tickets_available,
				email_content: assignWinnersInputValues.email_content,
				add_to_waiting_list: assignWinnersInputValues.add_to_waiting_list
			})

			// submit data
			const apiService = new ApiService()
			await apiService.post('ballots/winners-confirm', {body: postData})
				.then(async (ballotResponse) => {
					if (ballotResponse.data?.success) {
						addNotification(ballotResponse.data?.message, 'success')
						// hide modal & reset selected ballots
						this.setState({
							modalConfirmAssignWinnersShow: false,
						})
					}
					else {
						addNotification('Unable to confirm winners', 'error')
					}
				})
				.catch(err => console.error(err))
		}
		else {
			return false;
		}
	}

	handleConfirmCloseEventShow = (clickEvent) => {
		let open = true;
		if ( clickEvent.target.classList.contains('button__text') ) {
			if ( clickEvent.target.closest('.button').classList.contains('disabled') ) {
				open = false;
			}
		}
		else if ( clickEvent.target.classList.contains('disabled') ) {
			open = false;
		}

		this.setState({
			modalConfirmCloseEventShow: open
		})
	}

	handleConfirmCloseEvent = async () => {
		const {event, winningBallotsCount} = this.state
		const {addNotification} = this.context

		// prepare data
		const postData = JSON.stringify({
			event_id: event.id,
			send_emails: winningBallotsCount > 0
		})

		// submit data
		const apiService = new ApiService()
		await apiService.put('ballots/finalise', {body: postData})
			.then(async (ballotResponse) => {
				if (ballotResponse.data?.success) {
					addNotification(ballotResponse.data?.message, 'success')
					this.setState({
						modalConfirmCloseEventShow: false,
					})
				}
				else {
					addNotification('Unable to close event', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	updateStateBallots = (ballot, remove = false) => {
		const {ballots, selectedBallots} = this.state

		// update ballots state
		ballots.forEach((existingBallot, key) => {
			if (existingBallot.id === ballot.id) {
				if (remove) {
					ballots.splice(key, 1)
				}
				else {
					ballots[key] = {...existingBallot, ...ballot}
				}
			}
		})

		// update selectedBallots state
		selectedBallots.forEach((existingBallot, key) => {
			if (existingBallot.id === ballot.id) {
				if (remove) {
					selectedBallots.splice(key, 1)
				}
				else {
					selectedBallots[key] = {...existingBallot, ...ballot}
				}
			}
		})

		this.setState({ballots, selectedBallots})
	}
}

export default AdminEventsBallotsPage
