
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button";

class AdminEventConfirmAssignWinners extends React.Component {
	state = {
		isConfirming: false
	}

	render() {
		const {handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Mark Selected Ballots as Winners?</h2>
				<p>By clicking 'YES' you confirm that all of this batch's winners have been selected.
					<br/><br/><b>Attention:<br/>
						Notification emails will be sent to the winners and payments processed.
						This action cannot be reversed.</b></p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Cancel</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorEndeavour>Yes</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = () => {
		const {event, handleConfirm} = this.props
		this.setState({
			isConfirming: true
		})
		handleConfirm(event.id)
	}
}

export default AdminEventConfirmAssignWinners
