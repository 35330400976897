
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button";

class AdminEventConfirmDelete extends React.Component {
	state = {
		inputValue: '',
		inputError: ''
	}

	render() {
		const {selectedBallotsCount, handleClose, handleConfirm} = this.props

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Remove {selectedBallotsCount} Selected Ballot{selectedBallotsCount > 1 && 's'}?</h2>
				<p>The ballots will be removed from this event.</p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>
						Back
					</Button></li>
					<li><Button onClick={() => handleConfirm()} colorPomegranate>
						Remove Ballots
					</Button></li>
				</ul>
			</div>
		</Modal>
	}
}

export default AdminEventConfirmDelete
