
// Libraries
import * as React from 'react'

// Services
import ApiService from '../../../services/api'

// Components
import Modal from '../../modal'
import InputFactory from '../../inputFactory'
import Button from '../../button'
import AdminEventViewRow from './adminEventViewRow'
import ReactTooltip from 'react-tooltip';
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Images
import emailPreview from '../../../img/emails/winner-accepted-email.jpg'

class AdminBallotAssignWinnersForm extends React.Component {
	state = {
		initialValues: {},
		inputValues: {},
		inputErrors: {},
		isConfirming: false,
		ballotIdsCount: 0
	}

	render() {
		const {handleClose, event} = this.props
		const {initialValues, inputValues, inputErrors, isConfirming, ballotIdsCount} = this.state

		return <Modal handleClose={handleClose} adminForm assignWinners>
			<div className="admin-form admin-form--assign-winners">
				<div className="admin-form__header">
					<div className="column">
						<h3>Assign Winners</h3>
						<p>{event.artist.name} - {event.venue?.addresses[0].city} - {event.venue?.name}</p>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form>
						<div className="admin-form__field-group">
							<AdminEventViewRow
								label="Tickets to assign *"
								value={<InputFactory
									type="number"
									name="tickets_available"
									value={inputValues['tickets_available']}
									error={inputErrors['tickets_available']}
									onChange={this.handleInputChange}
								/>} />
							<AdminEventViewRow
								label={<>
									Ballot IDs (override)
									<FontAwesomeIcon icon={faInfoCircle} data-tip data-for={`tooltip-ballot-ids`}
													 className='tooltip-icon' multiline={true} html={true} />
								</>}
								value={<>
									<InputFactory
										type="textarea"
										name="ballot_ids"
										placeholder="Comma-seperated list of Ballot IDs"
										rows="3"
										value={inputValues['ballot_ids']}
										error={inputErrors['ballot_ids']}
										onChange={this.handleInputChange}
									/>
									<ReactTooltip id={`tooltip-ballot-ids`} place="right" backgroundColor="#005EB8" textColor="#fff">
										<span>Please enter a comma-separated list of ballot IDs. (Column A of the
											Ballots CSV file). We will assign tickets to these users, then randomly
											assign the remaining tickets.</span>
									</ReactTooltip>
									<p className="ballot-ids-count">{ballotIdsCount} override tickets</p>
								</>} />
							<AdminEventViewRow
								label={<>
									Ballot Winner Accepted Custom Content *<br/>
									<small><a href={emailPreview} target="_blank" rel="noreferrer">
										See where this content is used within the "Winner Accepted" email</a></small>
								</>}
								value={<InputFactory
									type="tinymce"
									name="email_content"
									initialValue={initialValues['email_content']}
									value={inputValues['email_content']}
									error={inputErrors['email_content']}
									onChange={this.handleInputChange}
									config="ballot-winners"
								/>} />
							<AdminEventViewRow
								label=""
								value={<InputFactory
									type="checkbox"
									name="add_to_waiting_list"
									label={<>
										Assign non-winners to waiting list?<br/>
										<small>If checked, an email will be sent to non-winners informing them they've
											been added to the waiting list</small>
									</>}
									checked={inputValues['add_to_waiting_list']}
									value={inputValues['add_to_waiting_list']}
									error={inputErrors['add_to_waiting_list']}
									onChange={this.handleInputChange}
									checkboxEndeavour
								/>} />
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Cancel</Button>
							<Button type="button" onClick={this.handleConfirm} isLoading={isConfirming} colorEndeavour>Review Winners</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	componentDidMount() {
		const {event} = this.props
		const {initialValues, inputValues} = this.state

		inputValues.tickets_available = event.max_tickets_per_transaction
		inputValues.add_to_waiting_list = true
		inputValues.email_content = event.email_content_winner_accepts
		initialValues.email_content = event.email_content_winner_accepts

		this.setState({
			initialValues: initialValues,
			inputValues: inputValues
		})
	}

	handleInputChange = (value, name, scope) => {
		const {event} = this.props
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		if (name === 'ballot_ids') {
			const ballotIdsCount = value.trim().replace(/(\r\n|\n|\r)/gm, '').split(',').filter(n => n).length * event.max_tickets_per_transaction
			this.setState({
				ballotIdsCount: ballotIdsCount
			})
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleConfirm = async () => {
		const {event, handleConfirm} = this.props
		const {inputValues} = this.state

		this.setState({
			isConfirming: true
		})

		const postData = JSON.stringify({
			event_id: event.id,
			...inputValues
		})

		// submit data
		const apiService = new ApiService()
		await apiService.post('ballots/winners-assign', {body: postData})
			.then(async (response) => {
				if (response.success) {
					handleConfirm(response.data, inputValues).then(() => {
						this.setState({
							isConfirming: false
						})
					})
				}
				else {
					this.setState({
						inputErrors: response.errors,
						isConfirming: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminBallotAssignWinnersForm
